<template>
  <div class="accordionPlay" data-testid="accordion-play">
    <div>
      <div
        class="accordionPlay__header"
        :class="{ active: defaultExpanded }"
        @click="toggleAccordion"
      > 
        <div class="accordionPlay__container">
          <template v-if="type === 'lesson'">
            <span class="accordionPlay__header--lesson" v-if="lock">
              <IconSax name="lock" primary />
            </span>
          </template>
          <template v-else>
            <span
              class="accordionPlay__header--icon"
              :class="{ 'accordion-icon': true, expanded: defaultExpanded }"
            >
              <IconSax name="arrow-square-down" primary />
            </span>
            <span class="accordionPlay__header--lock" v-if="lock">
              <IconSax name="lock" primary />
            </span>
          </template>
          <span class="accordionPlay__header--title">
            {{ title }}
          </span>
        </div>
        <div v-if="manageCategory" class="accordionPlay__manage-category" @click.stop="openCategoryModal">
          <IconSax name="edit" size="1rem" />
          <span>{{ $t('empty_state.manage_category') }}</span>
        </div>
      </div>
    </div>
 
    <div class="accordionPlay__content" v-if="defaultExpanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import IconSax from "./IconSax.vue";
import ModalCategoria from "@/components/ModalCategoria.vue";

export default {
  props: {
    title: String,
    lock: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ""
    },
    item: Object,
    defaultExpanded: {
      type: Boolean,
    },
    manageCategory: {
      type: Boolean,
      default: false
    }
  },
  components: {
    IconSax,
    ModalCategoria
  },
  watch: {
    defaultExpanded(newValue) {
      if (newValue === true) {
        this.expanded = true;
      } else {
        this.expanded = false;
      }
    },
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    toggleAccordion() {
      this.$emit("toggle")
    },
    openCategoryModal() {
      this.$root.$emit("bv::show::modal", "modal-categoria", "#btnShow");
    }
  }
};
</script>

<style lang="scss" scoped>
.accordionPlay {
  border-radius: var(--default-radius);

  &__container{
    display: flex;
    gap: 16px;
  }

  &__manage-category{
    display: flex;
    gap: 5px;
    margin-right: 3em;

    span{
      color: #878787;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.6px;
      white-space: nowrap;
    }

    ::v-deep .svg-inner path {
      fill: #878787;
    }
  }

  &__header {
    border-radius: 8px 8px 0px 0px;
    height: 60px;
    padding: 12px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--fontcolor);
    background: var(--accordion-header);
    transition: background-color 0.3s ease, color 0.3s ease;
    justify-content: space-between;

    &:hover {
      background: var(--accordion-header-active-hover);
      color: var(--maincolor);

      ::v-deep .svgCss svg path {
        fill: var(--maincolor);
      }
    }

    &:active,
    &.active {
      background: var(--accordion-header-active-hover);
      color: var(--maincolor);
      ::v-deep .svgCss svg path {
        fill: var(--maincolor);
      }
    }

    &--lesson {
        ::v-deep svg {
          width: 18px;
          height: 18px;
        } 
        ::v-deep svg path {
          fill: var(--opposite-color);
        }
      }

    &--lock {
      background: var(--accordion-header-lock);
      padding: 8px;
      border-radius: var(--default-radius);

      ::v-deep svg path {
        fill: var(--accordion-header-lock-icon) !important;
      }

      ::v-deep svg {
        width: 16px;
        height: 16px;
      }

      
    }

    &--icon {
      ::v-deep svg {
          width: 20px;
          height: 20px;
      }
      ::v-deep .svgCss svg path {
        fill: var(--maincolor);
      }
    }

    span {
      display: flex;
      align-items: center;
    }

    &--title {
      @extend .headline-semibold-20;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; 
    }
  }

  &__content {
    background: var(--accordion-content);
    padding: 16px;

    /* Desktop */
    @media screen and (min-width: 900px) {
      padding: var(--spacing-24) var(--spacing-48);
    }
  }

  .expanded {
    transform: rotate(180deg);
  }
}
</style>
